import React from "react";
import LoginForm from "../../components/LoginForm/LoginForm";

export default function Login() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}
